import styled from 'styled-components'

export const BreadcrumbLite = styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-left: 2px;
    margin-top: 0px;
    max-width: 1400px;
    list-style-type: none;

`

export default BreadcrumbLite;